import React, { useContext, useState, useEffect } from "react";
import UserContext from "../context/UserContext";
import airdropperConstructor from "../contracts/airdropperConstructor";
import web3 from "../web3";
import { AirdropperAbi } from '../constants.js';

const Airdropper = () => {
  const { user } = useContext(UserContext);
  const [smartcontract, setSmartcontract] = useState("");
  const [rewards, setRewards] = useState("");
  const [precision, setPrecision] = useState("");
  const [winnerList, setWinnerList] = useState("");
  const [winnerRewards, setWinnerRewards] = useState("");
  const [airdropperAddress, setAirdropperAddress] = useState("");
  const [message, setMessage] = useState("");
  const [renderState, setRenderState] = useState('');
  const [airdropper, setAirdropper] = useState(null);
  const [airdropLink, setAirdropLink] = useState('');
  let airdropCreatedSubscription;

  // Update airdropper contract instance when airdropperAddress changes
  useEffect(() => {
    if (airdropperAddress) {
      if(!web3.utils.isAddress(airdropperAddress)){
        setMessage("Smartcontract address is invalid");
        return;
      }
      else
        setMessage("");
      try {
        const newAirdropper = new web3.eth.Contract(AirdropperAbi, airdropperAddress);
        setAirdropper(newAirdropper);
      } catch (error) {
        console.error("Error creating contract instance:", error);
      }
    }

    airdropCreatedSubscription = airdropperConstructor.events.newAirdropperCreated({}, handleNewAirdropperCreated);
    return() =>{
      if (airdropCreatedSubscription) airdropCreatedSubscription.unsubscribe();
    };
  }, [airdropperAddress]);

  const handleNewAirdropperCreated = (error, event) => {
    console.log("I am in event handler");
    if (error) {
      console.error("Error in newAirdropperCreated event:", error);
      return;
    }
    if (!event || !event.returnValues) {
      console.error("Invalid event data:", event);
      return;
    }

    console.log(event);

    const { smartContract, decimals, airdropCreator, newAirdropper, details } = event.returnValues;
    if (airdropCreator.toLowerCase() === user.walletAddress.toLowerCase()) {
      const data = `New Airdrop created with below details:
                    \nToken smartContract address: ${smartContract}
                    \nToken precision: ${decimals}
                    \nAirdrop Creator: ${airdropCreator}
                    \nAirdropper address: ${newAirdropper}
                    \nAirdrop details: ${details}
                    \nPlease send all rewards tokens to above airdropper address otherwise airdropper will not function`;
      const formattedData = data.split('\n').map((str, index) => <p key={index}>{str}</p>);
      console.log(event.returnValues);
      setMessage(formattedData);
    }
  };

  const startNewAirdrop = async (event) => {
    event.preventDefault();

    if (user.walletAddress === 'Connect wallet') {
      alert('Connect wallet first');
    } else {
      setMessage('Waiting for transaction...');
      if(!web3.utils.isAddress(smartcontract)){
        setMessage("Smartcontract address is invalid");
        return;
      }
      console.log(message);
      try {
        //get latest nonce
        const nonce1 = await web3.eth.getTransactionCount(user.walletAddress, "latest");
        // get gas price
        const gasPriceLatest = await web3.eth.getGasPrice();
        await airdropperConstructor.methods.createNewAirdropper(smartcontract, precision, rewards, airdropLink).send({
          from: user.walletAddress,
          nonce: nonce1,
          gasPrice: gasPriceLatest
        });
      } catch (err) {
        if (err.code === 4001) {
          alert('Transaction rejected');
        } else if(err.code === -32000){
          alert('Invalid parameters');
        }else {
          console.log(err);
        }
      }
    }
  };

  const updateWinnersList = async (event) => {
    event.preventDefault();
    console.log(user.walletAddress);

    if (user.walletAddress === 'Connect wallet') {
      alert('Connect wallet first');
      return;
    }else {
      setMessage('Waiting for transaction...');
      console.log(message);
      try {
        // Split the comma-separated values into arrays
        const winnerListArray = winnerList.split(',').map(item => item.trim());
        const winnerRewardsArray = winnerRewards.split(',').map(item => parseInt(item.trim(), 10));

        // Ensure arrays have the same length
        if (winnerListArray.length !== winnerRewardsArray.length) {
            alert('The number of addresses and rewards do not match.');
            return;
        }

        // Ensure all addresses are valid
        const validAddresses = winnerListArray.every(addr => web3.utils.isAddress(addr));
        if (!validAddresses) {
            alert('One or more addresses are invalid.');
            return;
        }

        // Log the inputs for debugging
        console.log("winnerListArray:", winnerListArray);
        console.log("winnerRewardsArray:", winnerRewardsArray);

        //get latest nonce
        const nonce1 = await web3.eth.getTransactionCount(user.walletAddress, "latest");
        // get gas price
        const gasPriceLatest = await web3.eth.getGasPrice();

        // Call the smart contract function with the arrays
        const txnReceipt = await airdropper.methods.updateWinners(winnerListArray, winnerRewardsArray).send({
            from: user.walletAddress,
            nonce: nonce1,
            gasPrice: gasPriceLatest
        });

        setMessage("Winners successfully updated at transactionHash: "+txnReceipt.transactionHash);
      } catch (err) {
        if (err.code === 4001) {
          alert('Transaction rejected');
        } else if(err.code === -32000){
          alert('Invalid parameters');
        }else {
          console.log(err);
        }
      }
    }
  };

  const allowClaim = async (event) => {
    event.preventDefault();
    console.log(user.walletAddress);

    if (user.walletAddress === 'Connect wallet') {
      alert('Connect wallet first');
    } else {
      setMessage('Waiting for transaction...');
      console.log(message);
      try {
        //get latest nonce
        const nonce1 = await web3.eth.getTransactionCount(user.walletAddress, "latest");
        // get gas price
        const gasPriceLatest = await web3.eth.getGasPrice();

        const txnReceipt = await airdropper.methods.toggleAllowClaim().send({
          from: user.walletAddress,
          nonce: nonce1,
          gasPrice: gasPriceLatest
        });
        const txnReceipt1 = await airdropper.methods.allowClaim().call();
        setMessage('Now winners can claim tokens: '+txnReceipt1);
      } catch (err) {
        if (err.code === 4001) {
          alert('Transaction rejected');
        } else if(err.code === -32000){
          alert('Invalid parameters');
        }else {
          console.log(err);
        }
      }
    }
  };

  const myRewards = async (event) => {
    event.preventDefault();
    console.log(user.walletAddress);

    if (user.walletAddress === 'Connect wallet') {
      alert('Connect wallet first');
    }
    else {
      setMessage('Waiting for transaction...');
      console.log(message);
      try {
        const txnReceipt = await airdropper.methods.participantRewards(user.walletAddress).call();
        const txnReceipt1 = await airdropper.methods.rewardClaimed(user.walletAddress).call();
        const data = `Connected wallet address reward details:
                    \nYour rewards: ${txnReceipt}
                    \nRewards claimed: ${txnReceipt1}`;
      const formattedData = data.split('\n').map((str, index) => <p key={index}>{str}</p>);
      setMessage(formattedData);
      } catch (err) {
        if (err.code === 4001) {
          alert('Transaction rejected');
        } else if(err.code === -32000){
          alert('Invalid parameters');
        }else {
          console.log(err);
          setMessage("Incorrect airdrop smartcontract address");
        }
      }
    }
  };

  const claimAirdrop = async (event) => {
    event.preventDefault();
    console.log(user.walletAddress);

    if (user.walletAddress === 'Connect wallet') {
      alert('Connect wallet first');
      return;
    } 
    if(!airdropper){
      setMessage('Enter correct airdrop smartcontract address');
      return;
    }
    let claimAllowCheck = await airdropper.methods.allowClaim().call();
    if (!claimAllowCheck){
      setMessage("Claim is currently not allowed by admin. Contact airdrop admin for support or visit airdrop details page.");
      return;
    }
    let claimConfirm = await airdropper.methods.rewardClaimed(user.walletAddress).call();
    console.log(claimConfirm);
    if(claimConfirm){
      setMessage("Rewards already claimed");
      return;
    }
    else {
      setMessage('Waiting for transaction...');
      console.log(message);

      
      try {
        //get latest nonce
        const nonce1 = await web3.eth.getTransactionCount(user.walletAddress, "latest");
        // get gas price
        const gasPriceLatest = await web3.eth.getGasPrice();


        const txnReceipt = await airdropper.methods.claimReward().send({
          from: user.walletAddress,
          nonce: nonce1,
          gasPrice: gasPriceLatest
        });
        const txnReceipt1 = await airdropper.methods.tokenSmartcontract().call();
        setMessage('Tokens successfully claimed and transferred to your wallet at transaction hash: ' + txnReceipt.transactionHash + ". You can import tokens in your wallet with token smartcontract address: "+txnReceipt1);
      } catch (err) {
        if (err.code === 4001) {
          alert('Transaction rejected');
        } 
        else if(err.code === -32000){
          alert('Invalid parameters');
        }else {
          console.log(err);
          setMessage("Enter correct airdrop smartcontract address");
        }
      }
    }
  };

  const airdropDetails = async (event) => {
    event.preventDefault();
    console.log(user.walletAddress);

    if (user.walletAddress === 'Connect wallet') {
      alert('Connect wallet first');
    } else {
      setMessage('Checking details');
      console.log(message);
      try {
        const txnReceipt1 = await airdropper.methods.tokenSmartcontract().call();
        const txnReceipt2 = await airdropper.methods.airdropCreator().call();
        const txnReceipt3 = await airdropper.methods.totalAirdropTokens().call();
        const txnReceipt4 = await airdropper.methods.totalDistributed().call();
        const txnReceipt5 = await airdropper.methods.airdropDetails().call();
        const txnReceipt6 = await airdropper.methods.allowClaim().call();
        
        const data = `Airdrop details:
                    \nToken smartContract address: ${txnReceipt1}
                    \nAirdrop Manager: ${txnReceipt2}
                    \nTotal airdrop rewards: ${txnReceipt3}
                    \nTotal rewards claimed: ${txnReceipt4}
                    \nAirdrop Details: ${txnReceipt5}
                    \nAirdrop claim allowed: ${txnReceipt6}`;
      const formattedData = data.split('\n').map((str, index) => <p key={index}>{str}</p>);
      setMessage(formattedData);
      } catch (err) {
        if (err.code === 4001) {
          alert('Transaction rejected');
        } 
        else if(err.code == -32000){
          alert('Invalid parameters');
        }
        else {
          console.log(err);
        }
      }
    }
  };

  const updateComponent = (whichComponentToRender) => {
    setRenderState(whichComponentToRender);
  };

  let componentToRender;

  switch (renderState) {
    case 'NewAirdrop':
      componentToRender = (
        <div>
          <form onSubmit={startNewAirdrop}>
            <div className="form-group">
              <label>Token Smartcontract Address</label>
              <input
                value={smartcontract}
                onChange={event => setSmartcontract(event.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Decimals </label>
              <input
                value={precision}
                onChange={event => setPrecision(event.target.value)}
                type="number"
                min={0}
              />
              <label>Rewards (without decimal) </label>
              <input
                value={rewards}
                onChange={event => setRewards(event.target.value)}
                type="number"
                min={0}
              />
              <label>Airdrop Details Link </label>
              <input
                value={airdropLink}
                onChange={event => setAirdropLink(event.target.value)}
              />
            </div>
            <div className="form-group">
              <button>Start</button>
            </div>
          </form>
          <i className="form-group">You can only create airdrop for tokens you hold and the amount of tokens you own.</i>
          <i className="form-group">Send total reward tokens to airdropper smartcontract once airdropper is created, otherwise you will not be able to use airdropper.</i>
        </div>
      );
      break;
    case 'UpdateAirdrop':
      componentToRender = (
        <div>
          <form onSubmit={updateWinnersList}>
            <div className="form-group">
              <label htmlFor="airdropperAddress">Airdropper Smartcontract address</label>
              <input
                id="airdropperAddress"
                value={airdropperAddress}
                onChange={event => setAirdropperAddress(event.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="winnerList">List of Winners (comma seperated addresses)</label>
              <input
                id="winnerList"
                value={winnerList}
                onChange={event => setWinnerList(event.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="winnerRewards">List of Rewards (comma seperated numbers without token precision decimals)</label>
              <input
                id="winnerRewards"
                value={winnerRewards}
                onChange={event => setWinnerRewards(event.target.value)}
              />
            </div>
            <div className="button-container form-group">
              <button>Update</button>
            </div>
          </form>
          <i className="form-group">Transfer reward tokens to airdropper address before calling this function or this transaction will fail.</i>
        </div>
      );
      break;
    case 'AllowClaim':
      componentToRender = (
        <div>
          <div className="d-flex justify-content-center">
            <form onSubmit={allowClaim}>
              <div className="form-group">
                <label>Airdropper Smartcontract address</label>
                <input
                  value={airdropperAddress}
                  onChange={event => setAirdropperAddress(event.target.value)}
                />
              </div>
              <div className="form-group">
                <button>Allow/Disallow Claim</button>
              </div>
            </form>
            <br /><br />
          </div>
          <i className="form-group">This is toggle switch to either allow or disallow reward claim.</i>
          <i className="form-group">Call once to allow and again to disallow. By default, claiming is not allowed.</i>
        </div>
      );
      break;
      case 'ClaimAirdrop':
        componentToRender = (
          <div>
            <div className="d-flex justify-content-center">
              <form onSubmit={myRewards}>
                <div className="form-group">
                  <label>Airdropper Smartcontract address</label>
                  <input
                    value={airdropperAddress}
                    onChange={event => setAirdropperAddress(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <button>Check My Rewards</button>
                </div>
              </form>
              <br /><br />
            </div>
            <div className="d-flex justify-content-center">
              <form onSubmit={claimAirdrop}>
                <div className="form-group">
                </div>
                <div className="form-group">
                  <button>Claim My Rewards</button>
                </div>
              </form>
              <br /><br />
            </div>
            <i className="form-group">Just put token airdropper address that airdrop manager has given and tokens will be directly transferred to your connected wallet address.</i>
            <i className="form-group">The function will only work once airdrop manager allows token claiming.</i>
            <i className="form-group">The function will only work if connected wallet has some rewards to be claimed.</i>
          </div>
        );
        break;
        case 'AirdropDetails':
          componentToRender = (
            <div>
              <div className="d-flex justify-content-center">
                <form onSubmit={airdropDetails}>
                  <div className="form-group">
                    <label>Airdropper Smartcontract address</label>
                    <input
                      value={airdropperAddress}
                      onChange={event => setAirdropperAddress(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <button>View Airdrop Details</button>
                  </div>
                </form>
                <br /><br />
              </div>
            </div>
          );
          break;
    default:
      componentToRender = <div></div>;
      break;
  }

  return (
    <div className="rendered-container">
      <div className="button-container justify-content-center">
        <button className={`m-1 button ${renderState === 'NewAirdrop' ? 'active' : ''}`} onClick={() => updateComponent('NewAirdrop')}>Create New Airdrop</button>
        <button className={`m-1 button ${renderState === 'UpdateAirdrop' ? 'active' : ''}`} onClick={() => updateComponent('UpdateAirdrop')}>Update Airdrop Details</button>
        <button className={`m-1 button ${renderState === 'AllowClaim' ? 'active' : ''}`} onClick={() => updateComponent('AllowClaim')}>Allow Claim</button>
        <button className={`m-1 button ${renderState === 'ClaimAirdrop' ? 'active' : ''}`} onClick={() => updateComponent('ClaimAirdrop')}>Claim Airdrop Rewards</button>
        <button className={`m-1 button ${renderState === 'AirdropDetails' ? 'active' : ''}`} onClick={() => updateComponent('AirdropDetails')}>Airdrop Details</button>
      </div>
      <div>{componentToRender}</div>
      <hr />
      <div>
        <h6>Most recent transaction details:
        </h6><br></br>
        {message}
      </div>
    </div>
  );
};

export default Airdropper;
