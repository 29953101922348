import React, { useContext, useState, useEffect } from "react";
import UserContext from "../context/UserContext";
import escrow from '../contracts/escrow';
import usdt from '../contracts/usdt';
import '../css/components.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import web3 from "../web3";

const NewEscrow = () => {
  const { user } = useContext(UserContext);
  const [recipient, setRecipient] = useState("");
  const [amount, setAmount] = useState("");
  const [senderContact, setSenderContact] = useState("");
  const [recipientContact, setrecipientContact] = useState("");
  const [escrowId, setEscrowId] = useState("");
  const [message, setMessage] = useState("");
  const [renderState, setRenderState] = useState("");
  const escrowContractAddress = "0x1b8F9992648d956b7C7f3Ef7974f828c0b279509";
  let componentToRender;

  //event handlers start

  useEffect(()=>{
    const handleEscrowCreated = (error, event) => {
        if (error) {
          console.error("Error in escrowCreated event:", error);
          return;
        }
        if (!event || !event.returnValues) {
          console.error("Invalid event data:", event);
          return;
        }
  
        const { Sender, Receiver, Amount, escrowNumber } = event.returnValues;
        if(Sender.toLowerCase()===user.walletAddress.toLowerCase()){
          const data = `Escrow created with Details: 
                        \nSender: ${Sender} 
                        \nReceiver: ${Receiver} 
                        \nAmount: ${Amount}
                        \nEscrow ID: ${escrowNumber}`;
          const formattedData = data.split('\n').map((str, index) => <p key={index}>{str}</p>);
          console.log(event.returnValues);
          setMessage(formattedData);
        }
        
      };
  
      const handleEscrowWithdrawalRequested = (error, event) => {
        if (error) {
          console.error("Error in escrowWithdrawalRequested event:", error);
          return;
        }
        if (!event || !event.returnValues) {
          console.error("Invalid event data:", event);
          return;
        }
  
        const { escrowNumber } = event.returnValues;
        if (escrowNumber === escrowId) {
          const data = `Escrow withdrawal request created for following escrow: 
                        \nEscrow ID: ${escrowNumber}`;
          // const formattedData = data.split('\n').map((str, index) => <p key={index}>{str}</p>);
          const formattedData = data.split('\n').map(str => <p>{str}</p>);
          setMessage(formattedData);
        }
      };
  
      const handleEscrowAgreed = (error, event) => {
        if (error) {
          console.error("Error in escrowAgreed event:", error);
          return;
        }
        if (!event || !event.returnValues) {
          console.error("Invalid event data:", event);
          return;
        }
  
        const { escrowNumber } = event.returnValues;
        if (escrowNumber === escrowId) {
          const data = `Escrow agreement request accepted for following escrow: 
                        \nEscrow ID: ${escrowNumber}
                        \nEscrow Tokens trasferred to escrow recipient.`;
          // const formattedData = data.split('\n').map((str, index) => <p key={index}>{str}</p>);
          const formattedData = data.split('\n').map(str => <p>{str}</p>);
          setMessage(formattedData);
        }
      };

      const handleEscrowDisputed = (error, event) => {
        if (error) {
          console.error("Error in escrowDisputed event:", error);
          return;
        }
        if (!event || !event.returnValues) {
          console.error("Invalid event data:", event);
          return;
        }
  
        const { escrowNumber } = event.returnValues;
        if (escrowNumber === escrowId) {
          const data = `Escrow dispute request generated for following escrow: 
                        \nEscrow ID: ${escrowNumber}
                        \nCreate a new topic in "Disputes" forum at https://freecryptotools.org`;
          const formattedData = data.split('\n').map((str, index) => <p key={index}>{str}</p>);
          setMessage(formattedData);
        }

      };
  
      const escrowCreatedSubscription = escrow.events.escrowCreated({}, handleEscrowCreated);
      const escrowWithdrawalRequestedSubscription = escrow.events.escrowWithdrawalRequested({}, handleEscrowWithdrawalRequested);
      const escrowAgreedSubscription = escrow.events.escrowAgreed({}, handleEscrowAgreed);
      const escrowDisputedSubscription = escrow.events.escrowDisputed({}, handleEscrowDisputed);
  
      return () => {
        escrowCreatedSubscription.unsubscribe();
        escrowWithdrawalRequestedSubscription.unsubscribe();
        escrowAgreedSubscription.unsubscribe();
        escrowDisputedSubscription.unsubscribe();
      };
  },[escrowId, user.walletAddress]);

  //event handlers end
  
  //Function definition starts

  //Create escrow start

  const startEscrow = async (event) =>{

    event.preventDefault();

    if (user.walletAddress === 'Connect wallet')
      alert('Connect wallet first');
      

    else {

        setMessage('Waiting for transaction...');
        console.log(message);

        if(!web3.utils.isAddress(recipient)){
          setMessage("Recipient address is invalid");
          return;
        }

        const actualAmount = amount*10**6;

        try{
          var walletBalance = await usdt.methods.balanceOf(user.walletAddress).call();
          
          if (walletBalance < actualAmount){
            setMessage("You do not have enough USDT balance to start this escrow");
            return;
          }
          
          //get latest nonce
          const nonce1 = await web3.eth.getTransactionCount(user.walletAddress, "latest");
          // get gas price
          const gasPriceLatest = await web3.eth.getGasPrice();
          
          setMessage("Please confirm next two transaction requests.");
          await usdt.methods.transfer(escrowContractAddress, actualAmount).send({
            from: user.walletAddress,
            nonce: nonce1,
            gasPrice: gasPriceLatest
            });

          console.log("usdt transferred");
          const nonce2 = await web3.eth.getTransactionCount(user.walletAddress, "latest");
          //Create escrow
          await escrow.methods.startEscrow(actualAmount, recipient, senderContact, recipientContact).send({
            from: user.walletAddress,
            nonce: nonce2,
            gasPrice: gasPriceLatest
            });
        }
        catch(err){
          if (err.code === 4001)
              alert('Transaction rejected');
          else
              console.log(err);
          return;
        }
      }
  }

  //Create escrow end

  //withdraw request start

  const withdrawRequest = async (event) =>{

    event.preventDefault();

    if (user.walletAddress === 'Connect wallet')
      alert('Connect wallet first');
      

    else {

       var temp1 = await escrow.methods.paused().call(); //Check if smartcontract is paused
        if(temp1){
          setMessage("Admins have currently paused all smartcontract functions. Contact admin for support.");
          return;
        }

        var temp2 = await escrow.methods.escrowIndex().call(); //Check if escrow exists
        if(escrowId > temp2){
          setMessage("Escrow does not exist");
          return;
        }

        var temp3 = await escrow.methods.escrowStatus(escrowId).call(); //Check escrow status
        if(temp3 >= 2){
          setMessage("Withdrawal request cannot be submitted. Check escrow details for current escrow status.");
          return;
        }
        console.log(user.walletAddress);
        var temp4 = await escrow.methods.escrowDetails(escrowId).call(); //Check if caller of function is recipient of escrow
        if(temp4.Receiver.toUpperCase() !== user.walletAddress.toUpperCase()){
          setMessage("You are not recipient of the escrow. Call this function from the recipient wallet address.");
          return;
        }



        setMessage('Escrow Withdrawal Requested: Waiting for transaction...');
        
        try{
          //get latest nonce
          const nonce1 = await web3.eth.getTransactionCount(user.walletAddress, "latest");
          // get gas price
          const gasPriceLatest = await web3.eth.getGasPrice();
            await escrow.methods.withdrawRequest(escrowId).send({
            from: user.walletAddress,
            nonce: nonce1,
            gasPrice: gasPriceLatest
            });
        } 
        catch(err){
            if (err.code === 4001)
                alert('Transaction rejected');
            else
                setMessage(err);
        }
      }
  }

  //withdraw request end

  //escrow acceptance by sender start

  const acceptRequest = async (event) =>{

    event.preventDefault();

    if (user.walletAddress === 'Connect wallet')
      alert('Connect wallet first');
      

    else {

      var temp1 = await escrow.methods.paused().call(); //Check if smartcontract is paused
        if(temp1){
          setMessage("Admins have currently paused all smartcontract functions. Contact admin for support.");
          return;
        }

        var temp2 = await escrow.methods.escrowIndex().call(); //Check if escrow exists
        if(escrowId > temp2){
          setMessage("Escrow does not exist");
          return;
        }

        var temp3 = await escrow.methods.escrowStatus(escrowId).call(); //Check escrow status
        if(temp3 >= 3){
          setMessage("Escrow acceptance request cannot be submitted. Check escrow details for current escrow status.");
          return;
        }

        var temp4 = await escrow.methods.escrowDetails(escrowId).call(); //Check if caller of function is recipient of escrow
        if(temp4.Sender.toUpperCase() != user.walletAddress.toUpperCase()){
          setMessage("You are not recipient of the escrow. Call this function from the recipient wallet address.");
          return;
        }

        setMessage('Escrow Accepted: Waiting for transaction...');
        
        try{
          //get latest nonce
          const nonce1 = await web3.eth.getTransactionCount(user.walletAddress, "latest");
          // get gas price
          const gasPriceLatest = await web3.eth.getGasPrice();
            await escrow.methods.acceptRequest(escrowId).send({
            from: user.walletAddress,
            nonce: nonce1,
            gasPrice: gasPriceLatest
            });
            } 
        catch(err){
            if (err.code === 4001)
                alert('Transaction rejected');
            else
                setMessage(err);
        }
      }
  }

  //escrow acceptance by sender end

  //create dispute start

  const createDispute = async (event) =>{

    event.preventDefault();

    if (user.walletAddress === 'Connect wallet')
      alert('Connect wallet first');
      

    else {

      var temp1 = await escrow.methods.paused().call(); //Check if smartcontract is paused
        if(temp1){
          setMessage("Admins have currently paused all smartcontract functions. Contact admin for support.");
          return;
        }

        var temp2 = await escrow.methods.escrowIndex().call(); //Check if escrow exists
        if(escrowId > temp2){
          setMessage("Escrow does not exist");
          return;
        }

        var temp3 = await escrow.methods.escrowStatus(escrowId).call(); //Check escrow status
        
        if(temp3 > 3){
          setMessage("Escrow dispute request cannot be submitted. Check escrow details for current escrow status.");
          return;
        }

        var temp4 = await escrow.methods.escrowDetails(escrowId).call(); //Check if caller of function is recipient of escrow
        console.log(temp4.Sender.toUpperCase() + " " + temp4.Receiver.toUpperCase());
        if(temp4.Sender.toUpperCase() != user.walletAddress.toUpperCase() && temp4.Receiver.toUpperCase() != user.walletAddress.toUpperCase()){
          setMessage("You are not recipient/sender of the escrow. Call this function from the recipient wallet address.");
          return;
        } 

        setMessage('Escrow dispute raise Requested: Waiting for transaction...');
        
        try{
          //get latest nonce
          const nonce1 = await web3.eth.getTransactionCount(user.walletAddress, "latest");
          // get gas price
          const gasPriceLatest = await web3.eth.getGasPrice();
            await escrow.methods.CreateDispute(escrowId).send({
            from: user.walletAddress,
            nonce: nonce1,
            gasPrice: gasPriceLatest
            });
        } 
        catch(err){
            if (err.code === 4001)
                alert('Transaction rejected');
            else
                setMessage(err);
        }
      }
  }

  //create dispute end

  //get escrow details start
  const escrowDetails = async (event) =>{

    event.preventDefault();

    if (user.walletAddress === 'Connect wallet'){
      alert('Connect wallet first');
      return;
    }
      

    else {

      var temp2 = await escrow.methods.escrowIndex().call(); //Check if escrow exists
      if(escrowId > temp2){
        setMessage("Escrow does not exist");
        return;
      }

        let details;
        let status;

        setMessage('Escrow details Requested: Waiting for transaction...');
        
        try{
            details = await escrow.methods.escrowDetails(escrowId).call();
            status = await escrow.methods.escrowStatus(escrowId).call();
            if(!details){
                setMessage('No details found about escrow on blockchain');
                return;
            }
            let currentEscrowStatus;
            switch(status){
                case '0': currentEscrowStatus = "Escrow does not exist"; break;
                case '1': currentEscrowStatus = "Escrow created"; break;
                case '2': currentEscrowStatus = "Escrow withdrawal request generated";  break;
                case '3': currentEscrowStatus = "Escrow is in dispute"; break;
                case '4': currentEscrowStatus = "Escrow transfer to recipient approved and initiated by sender"; break;
                default : currentEscrowStatus = "Escrow settled"; break;

            }

            const data = `Escrow Details: 
                            \nSender: ${details.Sender} 
                            \nReceiver: ${details.Receiver} 
                            \nAmount: ${details.Amount}
                            \nEscrow Status: ${currentEscrowStatus}`;

            const formattedData = data.split('\n').map(str => <p>{str}</p>);
            setMessage(formattedData);
        } 
        catch(err){
            if (err.code === 4001)
                alert('Transaction rejected');
            else
                setMessage(err);
        }
      }
  }

  //get escrow details end

  //Function definitions end

  //update rendered component start

  const updateComponent = (whichComponentToRender) => {
    setRenderState(whichComponentToRender);

  }

  //update rendered component end

  //switch between components to be rendered start

  switch(renderState){
    case 'startEscrow': 
        componentToRender =<div>
        
            <form onSubmit={startEscrow}>
                <div className="form-group">
                    <label>Recipient</label>
                    <input
                    value={recipient}
                    onChange={event => setRecipient(event.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>USDT Amount</label>
                    <input
                    value={amount}
                    onChange={event => setAmount(event.target.value)}
                    type="number"
                    step="0.000001"
                    min={0}
                    />
                    <label>Sender Contact</label>
                    <input
                    value={senderContact}
                    onChange={event => setSenderContact(event.target.value)}
                    type="string"
                    min={0}
                    />
                    <label>Recipient Contact</label>
                    <input
                    value={recipientContact}
                    onChange={event => setrecipientContact(event.target.value)}
                    type="string"
                    min={0}
                    />
                </div>
                <div className="form-group">
                    <button>Start</button>
                </div>
            </form>
            <i>You must be sender of the escrow to call this function.</i><br />
            <i>You will have to approve 2 transactions to initiate the escrow.</i><br />
            <i>USDT smartcontract address on Polygon: 0xc2132D05D31c914a87C6611C10748AEb04B58e8F</i><br />
            <i>Refer <a href="https://docs.freecryptotools.org/" target="_blank">/help</a> for more details.</i>
        </div>;  
    break;
    case 'withdrawRequest': 
        componentToRender = <div>    
            <form onSubmit={withdrawRequest}>
                <div className="form-group">
                    <label>Escrow ID</label>
                    <input
                    value={escrowId}
                    onChange={event => setEscrowId(event.target.value)}
                    type="number"
                    min={0}
                    />
                </div>
                <div className="form-group">
                    <button>Start</button>
                </div>
            </form>
            <i className="form-group">You must be recepient of escrow to call this function.</i>
        </div>;
    break;
    case 'acceptRequest': 
        componentToRender = <div>
            <form onSubmit={acceptRequest}>
            <div className="form-group">
                <label>Escrow ID </label>
                <input
                value={escrowId}
                onChange={event => setEscrowId(event.target.value)}
                type="number"
                min={0}
                />
            </div>
            <div className="form-group">
                <button>Start</button>
            </div>
        </form>
        <i className="form-group">You must be sender of the escrow to call this function.</i>
        </div>;
    break;
    case 'createDispute':
        componentToRender = <div>
            <form onSubmit={createDispute}>
            <div className="form-group">
                <label>Escrow ID </label>
                <input
                value={escrowId}
                onChange={event => setEscrowId(event.target.value)}
                type="number"
                min={0}
                />
            </div>
            <div className="form-group">
                <button>Start</button>
            </div>
        </form>
        <i className="form-group">You must be sender/recepient of the escrow to call this function.</i>
        </div>;
        break;
        case 'escrowDetails':
            componentToRender = <div>
                <form onSubmit={escrowDetails}>
                <div className="form-group">
                    <label>Escrow ID </label>
                    <input
                    value={escrowId}
                    onChange={event => setEscrowId(event.target.value)}
                    type="number"
                    min={0}
                    />
                </div>
                <div className="form-group">
                    <button>Get details</button>
                </div>
            </form>
            </div>;
            break;
    default: 
        componentToRender = <div></div>;
    break;
  }
  //switch between components to be rendered end

  return (
    <div className="rendered-container">
      <div className="button-container justify-content-center">
        <button className="m-1 button `${renderState === 'startEscrow' ? 'active' : ''}`" onClick={() => updateComponent('startEscrow')}>
            Start New Escrow</button>
        <button className="m-1 button `${renderState === 'withdrawRequest' ? 'active' : ''}`" onClick={() => updateComponent('withdrawRequest')}>Withdrawal Request</button>
        <button className="m-1 button `${renderState === 'acceptRequest' ? 'active' : ''}`" onClick={() => updateComponent('acceptRequest')}>Accept Escrow</button>
        <button className="m-1 button `${renderState === 'createDispute' ? 'active' : ''}`" onClick={() => updateComponent('createDispute')}>Create Dispute</button>
        <button className="m-1 button `${renderState === 'escrowDetails' ? 'active' : ''}`" onClick={() => updateComponent('escrowDetails')}>Escrow Details</button>
      </div>
      <div>{componentToRender}</div><hr />
        <h6>Most recent transaction details:
        </h6>
      <div  className="rendered-container">
        {message}
      </div>
    </div>
  );
};


export default NewEscrow;